<template>
  <footer>
  </footer>
</template>

<script>
export default {

}
</script>

<style>
  footer {
    height: 25vh;
  }
</style>